import React, { useState, useEffect, useRef } from "react";
import IframeComponent from '../../components/Iframe/Iframe'
import { useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../auth/authConfig';
import { callMsGraph } from '../../auth/graph';
import * as XLSX from "xlsx";
import { NavLink } from "react-router-dom";
import html2canvas from "html2canvas";

function MainContent() {
    const { state, page } = useLocation()
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [accessAuthToken, setAccessAuthToken] = useState(null);
    const [siteMapData, setSiteMapData] = useState(null);

    useEffect(() => {
        // console.log("here", state, page)
        if (state?.page === 'siteMapPage') {
            getJsonData()
        }
        generateAuthToken()
        // RequestProfileData()
    }, [state, page]);

    const generateAuthToken = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(process.env.REACT_APP_BACKEND_URL+'/getEmbedToken', requestOptions)
            .then(response => response.text())
            .then(result => {
                // console.log(JSON.parse(result))
                localStorage.setItem("embedToken", JSON.parse(result).accessToken)
                setAccessAuthToken(JSON.parse(result).accessToken)
                RequestProfileData()
            })
            .catch(error => console.log('error', error));
    }

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                localStorage.setItem("accessToken", response.accessToken)
                callMsGraph(response.accessToken).then((response) => setGraphData(response));

            });
    }

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    const getJsonData = () => {
        fetch('KPI_Parameters.xlsx', {
            method: "GET",
        })
            .then((res) => res.blob())
            .then(async (data) => {
                const reader = new FileReader();
                const rABS = !!reader.readAsBinaryString;
                if (rABS) {
                    reader.readAsBinaryString(data);
                } else {
                    reader.readAsArrayBuffer(data);
                }
                reader.onload = (e) => {
                    const bstr = e.target.result;
                    const wb = XLSX.read(bstr, {
                        type: rABS ? "binary" : "array",
                        bookVBA: true,
                    });
                    const sheetName = wb.SheetNames[0];
                    const ws = wb.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(ws);
                    // console.log(data);

                    var newData = []
                    data.map((e) => {
                        var index = newData.findIndex(x => x.title == e['Report Name']);
                        if (index === -1) {
                            newData.push({
                                "title": e['Report Name'], "sortData": (e['Report Name'] == 'PV Dashboard' ? 1 : (e['Report Name'] == 'Delight To Win' ? 2 : (e['Report Name'] == 'Sales' ? 3 : 4)))
                                , "redirectTo": (e['Report Name'] == 'PV Dashboard' ? 'pv-dashboard' : (e['Report Name'] == 'Delight To Win' ? 'pv-custom-delight-to-win' : (e['Report Name'] == 'Sales' ? 'pv-custom-sales' : 'pv-custom-manufacturing')))
                                // , "ReportId": (e['Report Name'] == 'PV Dashboard' ? '090eccb6-f24f-44ac-9843-8dce986150f8' : (e['Report Name'] == 'Delight To Win' ? '5603d789-eb16-4eb8-ac05-5bd769e8ebd7' : (e['Report Name'] == 'Sales' ? '986f4c09-1d57-4d08-9351-5ca417136fe6' : '23ec1401-f148-4ad9-be05-0eabbf6087f7')))
                                // , "PageId": (e['Report Name'] == 'PV Dashboard' ? 'ReportSectiona20b6dfd08cea73438a7' : (e['Report Name'] == 'Delight To Win' ? 'ReportSection1210ad00d70646a07204' : (e['Report Name'] == 'Sales' ? 'ReportSection805e31a31ddbde516e7e' : 'ReportSection9e294395c2a61392590c')))
                                , ReportId: e['Report Id']
                                , PageId: e['Report Page Id']
                                , subData: getPageName(data, e['Report Name'])
                            })
                        }
                    })
                    sortByKey(newData, 'sortData')
                    setSiteMapData(newData)
                    // console.log(newData)
                };
            });
    }

    function sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    function getPageName(data, reportName) {
        var newData = []
        data.map((e) => {
            var index = newData.findIndex(x => x.title == e['Page Name ']);
            if (index === -1) {
                if (e['Page Name '] != '' && e['Page Name '] != undefined && e['Report Name'] == reportName) {
                    newData.push({
                        "title": e['Page Name ']
                        , "redirectTo": (e['Report Name'] == 'PV Dashboard' ? 'pv-dashboard' : (e['Report Name'] == 'Delight To Win' ? 'pv-custom-delight-to-win' : (e['Report Name'] == 'Sales' ? 'pv-custom-sales' : 'pv-custom-manufacturing')))
                        , "ReportId": e['Report Id']
                        , "PageId": e['Report Category Id']
                        // , "ReportId": (e['Report Name'] == 'PV Dashboard' ? '090eccb6-f24f-44ac-9843-8dce986150f8' : (e['Report Name'] == 'Delight To Win' ? '5603d789-eb16-4eb8-ac05-5bd769e8ebd7' : (e['Report Name'] == 'Sales' ? '986f4c09-1d57-4d08-9351-5ca417136fe6' : '23ec1401-f148-4ad9-be05-0eabbf6087f7')))
                        // , "PageId": (e['Report Name'] == 'PV Dashboard' ? 'ReportSectiona20b6dfd08cea73438a7' : (e['Report Name'] == 'Delight To Win' ? 'ReportSection1210ad00d70646a07204' : (e['Report Name'] == 'Sales' ? 'ReportSection805e31a31ddbde516e7e' : 'ReportSection9e294395c2a61392590c')))
                        // , subData: getKpiName(data, reportName, e['Page Name '])
                        , subData: getSubCategory(data, reportName, e['Page Name '])
                    })
                }
            }
        })
        return newData
    }

    function getSubCategory(data, reportName, pageName) {
        var newData = []
        data.map((e) => {
            var index = newData.findIndex(x => x.title == e['Category']);
            if (index === -1) {
                if (e['Page Name '] != '' && e['Page Name '] != undefined && e['Page Name '] == pageName && e['Report Name'] == reportName && e['Category'] != '') { //&& e['Summary Y/N'] == 'Y'
                    newData.push({
                        "title": e['Category']
                        , "ReportId": e['Report Id'] != '' && e['Report Id'] != undefined && e['Report Id'] != null ? e['Report Id'] : ''
                        , "PageId": e['Page Id'] != '' && e['Page Id'] != undefined && e['Page Id'] != null ? e['Page Id'] : ''
                        , subData: getKpiName(data, reportName, e['Page Name '], e['Category'])
                    })
                }
            }
        })
        return newData
    }

    function getKpiName(data, reportName, pageName, catName) {
        var newData = []
        data.map((e) => {
            var index = newData.findIndex(x => x.title == e['Display']);
            if (index === -1) {
                if (e['Page Name '] != '' && e['Page Name '] != undefined && e['Page Name '] == pageName && e['Report Name'] == reportName && (e['Category'] == catName || e['Category'] == '')) { //&& e['Summary Y/N'] == 'Y'
                    newData.push({
                        "title": e['Display']
                        , "ReportId": e['Report Id'] != '' && e['Report Id'] != undefined && e['Report Id'] != null ? e['Report Id'] : ''
                        , "PageId": e['Page Id'] != '' && e['Page Id'] != undefined && e['Page Id'] != null ? e['Page Id'] : ''
                        , subData: []
                    })
                }
            }
        })
        return newData
    }


    const iframeRef = useRef(null);
    const [snapshotURL, setSnapshotURL] = useState(null);






    return (
        <>
            <div className={`w-full h-full text-black  `} ref={iframeRef} id="takeScreenShotId">
                <div className='w-full flex flex-col gap-[15px] pt-[10px] px-[40px]' style={{ borderBottom: state?.page === 'siteMapPage' ? '1.3px solid #e6e6e6' : '', paddingLeft: "30px" }}>
                    {/*  */}
                    {/* shadow-[0px_1px_1px_0px_rgba(0,0,0,0.15)] */}
                    <div className='w-full flex items-center justify-between'>
                        <div>
                            <h1 className='font-black'>DHRUVA</h1>
                        </div>
                        <div className='flex gap-[20px] items-center'>


                            {/* <button data-bs-toggle="modal" data-bs-target="#refreshModel" class="btn btn-outline-danger">Loader
                                Button</button>
                            <div class="modal fade" id="refreshModel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content"></div>
                                    <div class="loader-screen d-flex flex-column">
                                        <span class="loader"></span>
                                        <span class="text-light mt-2">Please Wait...</span>
                                        <div class="loader-text">
                                            <p class="text-light mt-2 text-center">Fetching KPI details from respective source. It’ll just take a
                                                moment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}





                            <div className='flex gap-[6px] items-center'>
                                <span className='w-[30px] h-[30px] rounded-full border-[1px] border-[#B5B5B6] bg-[#B5B5B6] text-black font-bold p-[4px] flex items-center justify-center'>{graphData?.displayName.split(" ")[0][0] + '' + graphData?.displayName.split(" ")[1][0]}</span>
                                <span className='font-bold'>Welcome, {graphData?.displayName}</span>
                            </div>
                            <span data-bs-toggle="modal" data-bs-target="#logoutModal" style={{ cursor: "pointer" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.6001 2.6001C1.6001 2.04781 2.04781 1.6001 2.6001 1.6001H10.4001C10.9524 1.6001 11.4001 2.04781 11.4001 2.6001V4.08705C11.4001 4.30797 11.221 4.48705 11.0001 4.48705C10.7792 4.48705 10.6001 4.30797 10.6001 4.08705V2.6001C10.6001 2.48964 10.5106 2.4001 10.4001 2.4001H2.6001C2.48964 2.4001 2.4001 2.48964 2.4001 2.6001V13.4001C2.4001 13.5106 2.48964 13.6001 2.6001 13.6001H10.4001C10.5106 13.6001 10.6001 13.5106 10.6001 13.4001V11.9131C10.6001 11.6922 10.7792 11.5131 11.0001 11.5131C11.221 11.5131 11.4001 11.6922 11.4001 11.9131V13.4001C11.4001 13.9524 10.9524 14.4001 10.4001 14.4001H2.6001C2.04781 14.4001 1.6001 13.9524 1.6001 13.4001V2.6001ZM11.5037 5.94594C11.6441 5.77535 11.8962 5.75086 12.0668 5.89122L14.2543 7.69122C14.3466 7.76721 14.4001 7.88052 14.4001 8.0001C14.4001 8.11968 14.3466 8.23299 14.2543 8.30897L12.0668 10.109C11.8962 10.2493 11.6441 10.2248 11.5037 10.0543C11.3634 9.88367 11.3879 9.63159 11.5584 9.49122L12.8845 8.4001H8.0001C7.77918 8.4001 7.6001 8.22101 7.6001 8.0001C7.6001 7.77918 7.77918 7.6001 8.0001 7.6001H12.8845L11.5584 6.50897C11.3879 6.3686 11.3634 6.11653 11.5037 5.94594Z" fill="#FF3E5B" />
                                </svg>
                            </span>

                            <div className="modal fade" id="logoutModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Logout</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="m-0 logout-text">Are you sure you want to logout</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn-style no-btn w-50" data-bs-dismiss="modal">No</button>
                                            <button type="button" className="btn-style yes-btn w-50" onClick={() => handleLogout("popup")} >Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {state?.page === 'siteMapPage' &&
                        <div>
                            <h1 className='pb-[8px] relative w-max flex items-center gap-[10px] font-semibold text-[18px]'>
                                Site Map
                                <span className='absolute bottom-0 left-0 bg-gradient-to-r from-[#FF3E5B] to-[#FFE1D8] w-full h-[4px] rounded'></span>
                            </h1>
                        </div>
                    }
                </div>


                {state?.page === 'siteMapPage' ?
                    <div className="dashboard-parent">
                        <div className="cards-content-div">
                            {siteMapData &&
                                siteMapData.map(function (reportName, index) {
                                    return (<div className="container-fluid mb-2" key={index}>
                                        <div className="row" >
                                            <div className="col-lg-3">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {/* <h6 className="title">{reportName['title']}</h6> */}
                                                        <NavLink
                                                            to={"../" + reportName['redirectTo']}
                                                            state={{ state: reportName.ReportId, page: reportName.PageId }}>
                                                            <h6 className="title">{reportName['title']}</h6>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            {reportName.subData.map(function (pageName, index) {
                                                return (<div className="col-lg-3" key={index + "inner"}>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <NavLink
                                                                to={"../" + pageName['redirectTo']}
                                                                state={{ state: pageName.ReportId, page: pageName.PageId }}>
                                                                <h6 className="title">{pageName['title']}</h6>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            {pageName.subData.map(function (catName, index) {
                                                                // return (<p className="data">{catName['title']}</p>)
                                                                return (
                                                                    <div key={index + "innerChild"}>
                                                                        {catName['title'] &&
                                                                            <NavLink
                                                                                to={'/home'}
                                                                                state={{ state: catName.ReportId, page: catName.PageId }}>
                                                                                <p style={{ "cursor": "pointer" }} className="data catClass">&bull;{" "}{catName['title']}</p>
                                                                            </NavLink>}

                                                                        {
                                                                            catName.subData.map(function (kpiName, index) {

                                                                                return (kpiName['title'] && <div className="col-lg-12 cat_name_subData" key={index + "reportName"}>
                                                                                    {<p className="data"> &bull;{" "}{kpiName['title']}</p>}
                                                                                </div>)
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                        <div className="bd-bottom"></div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    :
                    <div className='pt-[20px]'>
                        {
                            accessAuthToken != null && accessAuthToken &&
                            graphData && graphData != null &&

                            // <IframeComponent key={state?.state == new Date() ? '090eccb6-f24f-44ac-9843-8dce986150f8' : state?.state} state={state?.state == null || state?.state == '' ? "090eccb6-f24f-44ac-9843-8dce986150f8" : state?.state} page={state?.page == null || state?.page == '' ? 'ReportSectiona20b6dfd08cea73438a7' : state?.page} />
                            <IframeComponent key={state?.state == new Date() ? '090eccb6-f24f-44ac-9843-8dce986150f8' : state?.state} state={state?.state == null || state?.state == '' ? "090eccb6-f24f-44ac-9843-8dce986150f8" : state?.state} page={state?.page == null || state?.page == '' ? 'ReportSectiona20b6dfd08cea73438a7' : state?.page} />
                        }
                    </div>
                }

                {snapshotURL && (
                    <div>
                        <h2>Snapshot:</h2>
                        <img src={snapshotURL} alt="Snapshot" />
                    </div>
                )}
            </div >



        </>
    )
}

export default MainContent