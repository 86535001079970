// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
    border: 0px;
    margin-top: 0px;
}

.noReport {
    text-align: center;
    margin-top: 20%;
}

.reportClass,
.reportClass iframe {
    width: 100%;
    display: block;
    height: calc(95vh - 4px);
    margin-top: -11px;

    /* width: 107%;
    display: block;
    height: 96vh;
    margin-left: -55px !important;
     */
}`, "",{"version":3,"sources":["webpack://./src/components/Iframe/Iframe.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,cAAc;IACd,wBAAwB;IACxB,iBAAiB;;IAEjB;;;;MAIE;AACN","sourcesContent":["iframe {\n    border: 0px;\n    margin-top: 0px;\n}\n\n.noReport {\n    text-align: center;\n    margin-top: 20%;\n}\n\n.reportClass,\n.reportClass iframe {\n    width: 100%;\n    display: block;\n    height: calc(95vh - 4px);\n    margin-top: -11px;\n\n    /* width: 107%;\n    display: block;\n    height: 96vh;\n    margin-left: -55px !important;\n     */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
