export const MenuConstants = [
    {
        "position": "Top",
        "key": 'HOME', "name": 'Home', "path": '/home', "reportId": '50305ede-0ea1-4a07-a797-a8e6bc766eef', 'pageName': 'ReportSection',
        'activeIcon': <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6453 5.22805L9.44855 0.993922C8.64617 0.336639 7.34886 0.336639 6.55398 0.986279L1.35727 5.22805C0.772355 5.7019 0.397412 6.70311 0.524892 7.45211L1.52224 13.5358C1.70221 14.6211 2.72206 15.5 3.8019 15.5H12.2006C13.273 15.5 14.3003 14.6134 14.4803 13.5358L15.4776 7.45211C15.5976 6.70311 15.2227 5.7019 14.6453 5.22805ZM8.00126 10.6774C6.96642 10.6774 6.12655 9.82138 6.12655 8.76667C6.12655 7.71196 6.96642 6.85597 8.00126 6.85597C9.03611 6.85597 9.87598 7.71196 9.87598 8.76667C9.87598 9.82138 9.03611 10.6774 8.00126 10.6774Z" fill="#FF3E5B"></path></svg></span>,
        'inActiveIcon': <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6453 5.22805L9.44855 0.993922C8.64617 0.336639 7.34886 0.336639 6.55398 0.986279L1.35727 5.22805C0.772355 5.7019 0.397412 6.70311 0.524892 7.45211L1.52224 13.5358C1.70221 14.6211 2.72206 15.5 3.8019 15.5H12.2006C13.273 15.5 14.3003 14.6134 14.4803 13.5358L15.4776 7.45211C15.5976 6.70311 15.2227 5.7019 14.6453 5.22805ZM8.00126 10.6774C6.96642 10.6774 6.12655 9.82138 6.12655 8.76667C6.12655 7.71196 6.96642 6.85597 8.00126 6.85597C9.03611 6.85597 9.87598 7.71196 9.87598 8.76667C9.87598 9.82138 9.03611 10.6774 8.00126 10.6774Z" fill="#0B0B0C" /></svg></span>,
        'activeLink': 0,
        'subMenu': [
            {
                "key": 'PVDASHBOARD', "name": 'PV Dashboard', "path": '/pv-dashboard', "reportId": '090eccb6-f24f-44ac-9843-8dce986150f8', 'pageName': 'ReportSectiona20b6dfd08cea73438a7', 'activeLink': 0,
                'subMenu': [
                    { "key": 'DELIGHTTOWIN', "name": 'Delight to Win', "path": '/pv-custom-delight-to-win', "reportId": '5603d789-eb16-4eb8-ac05-5bd769e8ebd7', 'pageName': 'ReportSection1210ad00d70646a07204', 'activeLink': 1 },
                    { "key": 'SALES', "name": 'Sales', "path": '/pv-custom-sales', "reportId": '986f4c09-1d57-4d08-9351-5ca417136fe6', 'pageName': 'ReportSection805e31a31ddbde516e7e', 'activeLink': 2 },
                    { "key": 'MANUFACTURING', "name": 'Manufacturing', "path": '/pv-custom-manufacturing', "reportId": '23ec1401-f148-4ad9-be05-0eabbf6087f7', 'pageName': 'ReportSection9e294395c2a61392590c', 'activeLink': 3 }
                ]
            }
        ]
    }
];


export const MenuConstantsBottom = [
    {
        "position": "Bottom", "key": 'SITEMAP', "name": 'Site Map', "path": '/sitemap', "reportId": '', 'pageName': 'siteMapPage',
        'activeIcon': <span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M8.0625 1.5H4.3125C2.7592 1.5 1.5 2.75919 1.5 4.31248V13.6875C1.5 15.2408 2.7592 16.5 4.3125 16.5H13.6875C15.2408 16.5 16.5 15.2408 16.5 13.6875V9.93745M11.812 1.50023L16.5 1.5M16.5 1.5V5.71884M16.5 1.5L8.53056 9.46848" stroke="#FF3E5B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>,
        'inActiveIcon': <span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.0625 1.5H4.3125C2.7592 1.5 1.5 2.75919 1.5 4.31248V13.6875C1.5 15.2408 2.7592 16.5 4.3125 16.5H13.6875C15.2408 16.5 16.5 15.2408 16.5 13.6875V9.93745M11.812 1.50023L16.5 1.5M16.5 1.5V5.71884M16.5 1.5L8.53056 9.46848" stroke="#0B0B0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>,
        'activeLink': 4,
        'subMenu': []
    }
];