import React from 'react'
import DesktopSidebar from './DesktopSidebar';

function Navbar({ handleTheme }) {

    return (
        <>
            <div className='w-max'>
                {/* desktop view sidebar */}
                <div className='slg:block hidden h-full' style={{borderRight:"1px solid rgba(0, 0, 0, 0.15)"}}>
                    <DesktopSidebar handleTheme={handleTheme} />
                </div>
            </div>
        </>
    )
}

export default Navbar