import React, { useState, useEffect } from 'react'
import { BsChevronDown, BsFillCaretLeftFill } from 'react-icons/bs';
import { NavLink } from "react-router-dom";
import { MenuConstants, MenuConstantsBottom } from '../../MenuConstant';

function Index({ isOpen }) {
    const [miniTabsOpen, setMiniTabsOpen] = useState(false);
    const pathname = window.location.pathname;
    const suggestPathname = '/suggest-order-qty' || '/admin/customer';
    const [submenu_4, setSubmenu_4] = useState(true);
    const [innerTabsActive, setInnerTabsActive] = useState(0);
    // console.log(window.location.href.split("#/")[1])

    useEffect(() => {
        // console.log(innerTabsActive)
    }, [innerTabsActive]);

    return (
        <div className='my-[1.5rem]' key={innerTabsActive}>


            {MenuConstants &&
                MenuConstants.map(function (level1, index1) {
                    return (
                        <button type='button' className={`flex flex-col ${isOpen ? 'justify-center group' : 'justify-between'} relative items-center w-full mb-[4px]`}>

                            <div
                                onClick={() => setSubmenu_4(!submenu_4)}
                                className={`flex items-center px-[14px] h-[40px] ${isOpen ? 'justify-center' : 'justify-between'} w-full ${submenu_4 === true && 'bg-[#F2F2F2]'} hover:bg-[#F2F2F2] hover:text-[#FF3E5B]`}>
                                <div className={` h-[32px] flex items-center gap-[8px] relative after:content-[''] after:absolute ${isOpen === false && innerTabsActive !== 4 ? 'after:-left-[14px]' : 'after:-left-[22px]'} after:top-0 after:bottom-0 after:w-[6px] after:h-full after:block after:z-40 after:bg-[#FF3E5B]`}>
                                    {innerTabsActive === 4 ? level1.inActiveIcon : level1.activeIcon}
                                    <NavLink
                                        onClick={() => { setInnerTabsActive(level1.activeLink) }}
                                        to={level1.path}
                                        state={{ state: level1.reportId, page: level1.pageName }}>
                                        <span className={`capitalize ${isOpen ? 'hidden' : ''} text-[16px] ${submenu_4 === true && 'text-[#FF3E5B]'}`}>{level1.name}</span>
                                    </NavLink>
                                </div>
                                {
                                    isOpen === false &&
                                    <BsChevronDown size={18} className={` ${submenu_4 === true && ('rotate-180 transition-all text-[#FF3E5B]')} transition-all`} />
                                }

                            </div>

                            {/* show tooltips */}
                            <div className={`group-hover:block hidden rounded-[5px] absolute top-1 left-[69px] w-[200px] p-[5px] h-max z-[99999] border-[1px] bg-white border-[#E6E6E6]`}>
                                {/* right arrow */}
                                <span className='absolute -left-[12px] top-[8px]'>
                                    <BsFillCaretLeftFill size={18} className='text-[#ff3e5b]' />
                                </span>
                                {/* list */}
                                <ul className='w-full flex flex-col'>
                                    {
                                        level1.subMenu && level1.subMenu.map(function (level2, index2) {
                                            return (<><li className={`w-auto whitespace-nowrap h-max ${miniTabsOpen === true && `text-[#FF3E5B]`} text-left pl-[16px] py-[2px] pr-[10px] rounded text-[12px]`}>
                                                <div onClick={() => { setMiniTabsOpen(!miniTabsOpen); setInnerTabsActive(level2.activeLink) }} className='w-full flex justify-between items-center h-max'>
                                                    <NavLink
                                                        to={level2.path}
                                                        state={{ state: level2.reportId, page: level2.pageName }}>
                                                        <span style={{ color: (miniTabsOpen === true || (window.location.href.split("#/")[1]) === 'pv-dashboard') && '#FF3E5B' }} className='text-[16px]'>{level2.name}</span>
                                                    </NavLink>
                                                    <BsChevronDown size={18} className={`${miniTabsOpen === true && 'text-[#FF3E5B]'} ${miniTabsOpen === true && ('rotate-180 transition-all')} transition-all`} />
                                                </div>
                                            </li>

                                                {miniTabsOpen === true &&
                                                    <ul className='w-full flex flex-col gap-1 mt-[0.2rem] bg-[#f2f2f2] p-[3px]'>
                                                        {
                                                            level2.subMenu && level2.subMenu.map(function (level3, index2) {
                                                                return (<NavLink
                                                                    onClick={() => setInnerTabsActive(level3.activeLink)}
                                                                    to={level3.path}
                                                                    state={{ state: level3.reportId, page: level3.pageName }}
                                                                    className={`w-auto whitespace-nowrap text-[16px] pl-[26px] ${pathname === suggestPathname && `text-[#FF3E5B] bg-[#F2F2F2]`} ${innerTabsActive === level3.activeLink ? `text-[#FF3E5B] bg-[#F2F2F2]` : `text-[#342C2C] hover:bg-[#fff]`} text-left rounded py-[5px]`}>
                                                                    {level3.name}
                                                                </NavLink>)
                                                            })
                                                        }
                                                    </ul>}</>
                                            )
                                        })
                                    }

                                </ul>
                            </div>

                            {/* submenu */}
                            <div className='w-full' key={innerTabsActive + "siteMap"}>
                                {
                                    submenu_4 && !isOpen &&
                                    <ul className='w-full flex flex-col'>
                                        {
                                            level1.subMenu && level1.subMenu.map(function (level2, index2) {
                                                return (<>
                                                    <li className={`w-auto whitespace-nowrap h-[40px] flex items-center text-left pl-[30px] pr-[14px] rounded py-[5px] text-[12px]`}>
                                                        <div onClick={() => { setMiniTabsOpen(!miniTabsOpen); setInnerTabsActive(level2.activeLink) }} className='w-full flex justify-between items-center'>
                                                            <NavLink
                                                                to={level2.path}
                                                                state={{ state: level2.reportId, page: level2.pageName }}>
                                                                <span className='text-[16px]' style={{ color: miniTabsOpen === true || innerTabsActive == level2.activeLink ? '#FF3E5B' : '' }}>{level2.name}</span>
                                                            </NavLink>
                                                            <BsChevronDown size={18} style={{ color: miniTabsOpen === true && '#FF3E5B' }} className={` text-black ${miniTabsOpen === true && ('rotate-180 transition-all')} transition-all`} />
                                                        </div>
                                                    </li>
                                                    {
                                                        miniTabsOpen === true &&
                                                        <ul className='w-full pl-[21px] flex flex-col gap-1 bg-[#f2f2f2]'>
                                                            {
                                                                level2.subMenu && level2.subMenu.map(function (level3, index3) {
                                                                    return (<NavLink
                                                                        onClick={() => setInnerTabsActive(level3.activeLink)}
                                                                        to={level3.path}
                                                                        state={{ state: level3.reportId, page: level3.pageName }}
                                                                        className={`w-auto whitespace-nowrap ${pathname === suggestPathname && `text-[#FF3E5B] bg-[#F2F2F2]`} ${innerTabsActive === level3.activeLink ? `text-[#FF3E5B] bg-[#F2F2F2]` : ` text-[#342C2C]`} text-left pl-[20px] rounded py-[5px] text-[16px]`}>
                                                                        {level3.name}
                                                                    </NavLink>)
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                </>)
                                            })
                                        }

                                    </ul>
                                }

                            </div>
                        </button>
                    )
                })
            }



            {/* Site Map Menu */}

            {MenuConstantsBottom &&
                MenuConstantsBottom.map(function (level1, index1) {
                    return (
                        <button type='button' className={`bottom-link flex flex-col ${isOpen ? 'justify-center group' : 'justify-between'} relative items-center w-full mb-[4px]`}>
                            <div
                                onClick={() => setSubmenu_4(false)}
                                className={`flex items-center px-[14px] h-[40px] ${isOpen ? 'justify-center' : 'justify-between'} w-full ${innerTabsActive === 4 && 'bg-[#F2F2F2]'} hover:bg-[#F2F2F2] hover:text-[#FF3E5B]`}>
                                <div className={` ${innerTabsActive === 4 ? '' : 'afterDisable'} h-[32px] flex items-center gap-[8px] relative after:content-[''] after:absolute ${isOpen === false ? 'after:-left-[14px]' : 'after:-left-[22px]'} after:top-0 after:bottom-0 after:w-[6px] after:h-full after:block after:z-40 after:bg-[#FF3E5B]`}>
                                    {innerTabsActive === 4 ? level1.activeIcon : level1.inActiveIcon}
                                    <NavLink
                                        onClick={() => { setInnerTabsActive(level1.activeLink) }}
                                        to={level1.path}
                                        state={{ state: level1.reportId, page: level1.pageName }}
                                        className={`capitalize ${isOpen ? 'hidden' : ''} text-[16px] ${innerTabsActive === 4 && 'text-[#FF3E5B]'}`}>
                                        {level1.name}
                                    </NavLink>
                                </div>
                            </div>

                            <div className={`group-hover:block hidden rounded-[5px] absolute top-1 left-[69px] w-[200px] p-[5px] h-max z-[99999] border-[1px] bg-white border-[#E6E6E6]`}>
                                <span className='absolute -left-[12px] top-[8px]'>
                                    <BsFillCaretLeftFill size={18} className='text-[#ff3e5b]' />
                                </span>
                                <ul className='w-full flex flex-col'>
                                    <li className={`w-auto whitespace-nowrap h-max ${miniTabsOpen === true && `text-[#FF3E5B]`} text-left pl-[16px] py-[2px] pr-[10px] rounded text-[12px]`}>
                                        <div onClick={() => { setInnerTabsActive(4) }} className='w-full flex justify-between items-center h-max'>
                                            <NavLink
                                                to={level1.path}
                                                state={{ state: level1.reportId, page: level1.pageName }}
                                                style={{ color: (miniTabsOpen === true || (window.location.href.split("#/")[1]) === 'sitemap') && '#FF3E5B' }} className='text-[16px]'>
                                                Site Map
                                            </NavLink>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </button>)
                })
            }
        </div>
    )
}

export default Index