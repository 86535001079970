import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth/authConfig';

export default function LoginPage() {
    const { instance } = useMsal();
    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

    return (
        <>
            <div className="parent-div">
                <div className="welcome-div ms-5 me-5 mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 p-5 hero-text-div">
                                <div className="row welcome-arrow">
                                    <div className="col-lg-12 mt-2 logClassHome" >
                                        <img src="../Logo=Mahindra Rise-Default.png" className="img-fluid me-4 ms-3" />
                                    </div>
                                </div>
                                <div className="row welcome-arrow">
                                    <div className="col-lg-12 mt-2">
                                        <h3 className="mt-2 text-center">Welcome to Mahindra</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <p className="manage-text">“Login to access Dhurva Reports”</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <button className="login-button mt-2" onClick={() => handleLogin("popup")}>
                                            <img src="../microsoft-logo.png" className="img-fluid me-4 ms-3" />
                                            Sign in with Microsoft
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="bottom-cloud">
                </div>
            </div>
        </>
    )
}