import { BrowserRouter, Route, Routes, useNavigate, useNavigation } from "react-router-dom";
import React, { useEffect } from 'react';
import DashboardPage from './pages/DashboardPage'
import LoginPage from "./pages/LoginPage";

import { useIsAuthenticated } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ROUTES } from "./constant";
import SiteMapPage from "./pages/SiteMap";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated || isAuthenticated == undefined || isAuthenticated == null || isAuthenticated == '') {
      navigate('/login')
    } else {
      // navigate('/home')
      navigate(ROUTES.PVDASHBOARD)
    }
  }, [isAuthenticated])

  return (
    <>
      <div key={isAuthenticated}>
        <UnauthenticatedTemplate>
          <Routes>
            <Route exact path='/login' element={<LoginPage />}></Route>
          </Routes>
        </UnauthenticatedTemplate>

        <AuthenticatedTemplate>
          <div key={isAuthenticated}>
            <Routes>
              <Route path='/' element={<DashboardPage />} />
              <Route path='/home' element={<DashboardPage />} />
              <Route path={ROUTES.PVDASHBOARD} element={<DashboardPage />} />
              <Route path={ROUTES.PVCUSTOMSALES} element={<DashboardPage />} />
              <Route path={ROUTES.PVCUSTOMMANUFACTURE} element={<DashboardPage />} />
              <Route path={ROUTES.PVCUSTOMDELIGHTTOWIN} element={<DashboardPage />} />
              {/* <Route path={ROUTES.SITEMAP} element={<SiteMapPage />} /> */}
              <Route path={ROUTES.SITEMAP} element={<DashboardPage />} />
            </Routes>
          </div>
        </AuthenticatedTemplate>
      </div>
    </>
  );
}

export default App;
